<template>
  <LoadingState v-if="isLoading" />
  <div :class="$style.dashboard" v-else>
    <h1>
      {{ panggilan }} {{ user.nama }},
      <span v-if="schoolReligion === 'Islam'" :id="$style['salam-arab']"
        >السلام عليكم ورحمة الله وبركاته</span
      >
    </h1>
    <a-row class="mt-5" :gutter="[32, 32]">
      <!-- <a-col v-if="!isDesktop" :md="{ span: 12 }" :lg="{ span: 24 }">
        <today-attendance />
      </a-col> -->
      <a-col :md="{ span: 24 }" :lg="{ span: 16 }">
        <div :id="$style.todayClass">
          <div class="d-flex flex-nowrap align-items-center pb-3">
            <div class="mr-auto">
              <h2 id="today-class">
                Today Class
                <span
                  v-if="sesis"
                  class="font-size-16 font-weight-normal"
                  style="color: #0000009c"
                  >{{ sesis.count ? sesis.count : 0 }} Class</span
                >
              </h2>
            </div>
            <div class="mt-3" style="color: #0000009c">{{ today }}</div>
          </div>
          <a-row v-if="sesis.count >= 2">
            <VueSlickCarousel v-bind="settings" ref="carousel">
              <a-col
                :xs="{ span: 24 }"
                :md="{ span: 12 }"
                :lg="{ span: 8 }"
                v-for="(item, index) in sesis.rows"
                :key="index"
              >
                <CardTodayClass
                  :item="item"
                  @click.prevent="toDetails(item.id)"
                />
              </a-col>
              <!-- <div v-for="(item, index) in sesis.rows" :key="index">
              <CardTodayClass :item="item" @click.prevent="toDetails(item.id)" />
              </div>-->
            </VueSlickCarousel>
          </a-row>
          <template v-else-if="sesis.count > 0 && sesis.count < 2">
            <a-row>
              <a-col
                :xs="{ span: 24 }"
                :md="{ span: 12 }"
                v-for="(item, index) in sesis.rows"
                :key="index"
              >
                <CardTodayClass
                  :item="item"
                  @click.prevent="toDetails(item.id)"
                />
              </a-col>
            </a-row>
          </template>
          <div v-else-if="!sesis.count || sesis.count <= 0" class="my-5">
            <EmptyState
              :heading="EmptyStateTodayClass.heading"
              :description="EmptyStateTodayClass.description"
            />
          </div>
        </div>
        <a-row :gutter="[32, 32]">
          <h2 class="mt-5 ml-4">
            Assignments in Progress
            <span
              v-if="assignmentInProgress.count"
              class="font-size-16 font-weight-normal"
              style="color: #0000009c"
              >{{ assignmentInProgress.count.length }} Session</span
            >
          </h2>
          <template v-if="assignmentInProgress.count.length">
            <div
              v-for="(item, index) in assignmentInProgress.rows"
              :key="index"
            >
              <a-col :xs="{ span: 24 }" :md="{ span: 12 }">
                <CardAssignment :item="item" />
              </a-col>
            </div>
          </template>
          <div
            v-else-if="
              !assignmentInProgress.count ||
              assignmentInProgress.count.length == 0
            "
            class="my-5"
          >
            <EmptyState
              :heading="EmptyStateAssignmentGrading.heading"
              :description="EmptyStateAssignmentGrading.description"
            />
          </div>
        </a-row>
      </a-col>
      <a-col :md="{ span: 24 }" :lg="{ span: 8 }">
        <a-row :gutter="32">
          <!-- <a-col v-if="isDesktop" :md="{ span: 12 }" :lg="{ span: 24 }">
            <today-attendance />
          </a-col> -->
          <template v-if="institusi.is_announcement">
            <a-col :md="{ span: 12 }" :lg="{ span: 24 }">
              <DashboardAnnouncement @fetch-announcement="fetchAnnouncement" :to="{ name: 'Announcement Teacher' }" :announcementData="announcementData" />
            </a-col>
          </template>
          <a-col :md="{ span: 12 }" :lg="{ span: 24 }">
            <div class="card assignmentsProgress">
              <div class="card-header card-header-flex">
                <div class="d-flex flex-column justify-content-center mr-auto">
                  <h2 class="my-auto font-size-18">Assignments in Progress</h2>
                </div>
                <!-- <div class="d-flex flex-column justify-content-center">
                  <a-icon
                    @click="toAssignment"
                    type="select"
                    class="font-size-18"
                    style="color: #1b90ff"
                  />
                </div>-->
              </div>
              <div class="card-body px-4">
                <div class="d-flex flex-nowrap align-items-center pr-4">
                  <div class="mr-auto">
                    <h3
                      v-if="assignmentInProgress.rows"
                      :class="$style['textSession']"
                    >
                      {{ assignmentInProgress.rows.length }}
                      <span>Sessions</span>
                    </h3>
                  </div>
                  <div class="d-flex flex-wrap align-items-center">
                    <h3 :class="$style['textSubmitted']">
                      {{
                        allAssignmentInProgress.data1 &&
                        allAssignmentInProgress.data1[0].tugas_submitted_all
                          ? allAssignmentInProgress.data1[0].tugas_submitted_all
                          : 0
                      }}
                    </h3>
                    <div :class="$style['textSpan']" class="ml-2">
                      <p>
                        /{{
                          allAssignmentInProgress.data1 &&
                          allAssignmentInProgress.data1[0].tugas_count_all
                            ? allAssignmentInProgress.data1[0].tugas_count_all
                            : 0
                        }}
                      </p>
                      <p>Submitted</p>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-nowrap align-items-center mt-3 pr-5">
                  <div class="mr-auto">
                    <h3 :class="$style['textClass']">
                      {{
                        allAssignmentInProgress &&
                        allAssignmentInProgress.students
                          ? allAssignmentInProgress.students
                          : 0
                      }}
                      <span>Students</span>
                    </h3>
                  </div>
                  <div class="d-flex flex-wrap align-items-center">
                    <h3 :class="$style['textGraded']">
                      {{
                        allAssignmentInProgress.data1 &&
                        allAssignmentInProgress.data1[0].tugas_graded_all
                          ? totalNilaiPercentage
                          : 0
                      }}
                    </h3>
                    <div :class="$style['textSpan']" class="ml-2">
                      <p>%</p>
                      <p>Graded</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :md="{ span: 12 }" :lg="{ span: 24 }">
            <div class="card">
              <div class="card-header card-header-flex">
                <div class="d-flex flex-column justify-content-center mr-auto">
                  <h2 class="my-auto font-size-18">
                    Biweekly Sessions Planning
                  </h2>
                </div>
                <!-- <div class="d-flex flex-column justify-content-center">
                  <a-icon type="select" class="font-size-18" style="color: #1b90ff" />
                </div>-->
              </div>
              <template v-if="biweeklySessionPlanning.length">
                <div
                  v-for="(session, index) in biweeklySessionPlanning"
                  :class="['card-header', $style.progressBiweekly]"
                  :key="index"
                  class
                >
                  <h4 aria-label="Title Progress Dashboard" @click="toPlanning">
                    {{ session["kela.tingkat"] }} |
                    {{ session["mata_pelajaran.nama"] }}
                  </h4>
                  <a-progress
                    :strokeWidth="15"
                    strokeColor="#40B983"
                    :percent="session.plannedRatio * 100"
                    :format="
                      (_) => `${session.planned} of ${session.total_sesi}`
                    "
                  />
                </div>
                <div :class="['card-body', $style.progressLegend]">
                  <a-progress
                    :strokeWidth="13"
                    :percent="100"
                    strokeColor="#40B983"
                    :format="(_) => `Planned`"
                  />
                  <!-- <a-row :gutter="16">
                    <a-col :span="15">
                      <div class="font-size-12" style="color: gray;">
                        <a-icon type="question-circle" class="mr-2" />unplanned
                      </div>
                    </a-col>
                    <a-col :span="9">
                      <div class="font-size-12" style="color: gray;">
                        <a-icon type="question-circle" class="mr-2" />planned
                      </div>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16" class="mt-2">
                    <a-col :span="15">
                      <div class="font-size-12" style="color: gray;">
                        <a-icon type="question-circle" class="mr-2" />today unplanned
                      </div>
                    </a-col>
                    <a-col :span="9">
                      <div class="font-size-12" style="color: gray;">
                        <a-icon type="question-circle" class="mr-2" />today planned
                      </div>
                    </a-col>
                  </a-row>-->
                </div>
              </template>
              <EmptyState
                style="margin: 20px 0"
                v-else
                heading="No Session"
                description="No biweekly sessions currently."
              />
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment'
import config from '@/config'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import EmptyState from '@/components/app/EmptyState'
import CardTodayClass from '@/components/app/Dashboard/TodayClass'
import CardAssignment from '@/components/app/Assignment/CardAssignment'
import LoadingState from '@/components/app/LoadingState'

const DashboardAnnouncement = () => import('@/components/app/DashboardAnnouncement')
// const TodayAttendance = () => import('@/components/app/Dashboard/TodayAttendance')
const settings = {
  arrows: true,
  centerMode: true,
  // variableWidth: true,
  focusOnSelect: false,
  infinite: true,
  slidesToShow: 2,
  speed: 500,
  centerPadding: '70px',
}
const marks1 = {
  1: '',
  2: 'this week',
  3: '',
  4: 'next week',
  5: '',
  6: '',
}
const marks2 = {
  1: '',
  2: 'this week',
  3: '',
  4: '',
}

const EmptyStateTodayClass = {
  heading: 'No Data',
  description: 'You have no class for today',
}

const EmptyStateAssignmentGrading = {
  heading: 'No Data',
  description: 'You have no assignment in progress',
}

const EmptyStateAnnouncement = {
  heading: 'No Data',
  description: 'There\'s no announcement right now',
}

const today = moment().format('D MMM YYYY')

export default {
  components: {
    EmptyState,
    VueSlickCarousel,
    CardTodayClass,
    CardAssignment,
    LoadingState,
    DashboardAnnouncement,
    // TodayAttendance,
  },
  data() {
    return {
      schoolReligion: '',
      today: today,
      uploadedFile: '',
      myHTML: '',
      announcementData: [],
      settings,
      marks1,
      marks2,
      EmptyStateTodayClass,
      EmptyStateAssignmentGrading,
      EmptyStateAnnouncement,
      moment,
    }
  },
  methods: {
    formatter(value) {
      return `${value} Aug 20`
    },
    toPlanning() {
      this.$router.push({ name: 'Planning Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Planning Teacher'],
      })
    },
    toAssignment() {
      this.$router.push({ name: 'Assignments Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Assignments Teacher'],
      })
    },
    toAllAnnouncement() {
      this.$router.push({ name: 'Announcement Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Announcement Teacher'],
      })
    },
    isMobile() {
      const isMobile = this.$store.getters.isMobile
      const isTablet = this.$store.getters.isTablet
      // console.log(isTablet)
      if (!isTablet && isMobile) {
        this.settings.centerPadding = '-20px'
        this.settings.slidesToShow = 1
      } else if (isTablet && !isMobile) {
        // console.log('masuk 150')
        this.settings.centerPadding = '-150px'
        this.settings.slidesToShow = 2
      } else {
        this.settings.centerPadding = '25px'
        // this.settings.centerPadding = '-20px'
        // this.settings.slidesToShow = 1
      }
    },
    async fetchAnnouncement() {
      try {
        const queryResult = await this.$store.dispatch('announcement/FETCH_ALL_ANNOUNCEMENT', { type: null, page: null, judul: null, limit: Number(2), role: 'Teacher' })
        // console.log('queryResult :>> ', queryResult)
        this.announcementData = queryResult.map(dat => {
          const tags = []
          let type = 'general'
          const { penerima_pengumumans: recipients } = dat
          if (recipients?.filter(pen => pen.id_kelas).length) type = 'id_kelas'
          else if (recipients?.filter(pen => pen.id_level).length) type = 'id_level'
          if (recipients?.length) {
            for (let i = 0; i < recipients?.length; i++) {
              const recipient = recipients[i]
              let tag = {}
              if (!recipient.id_kelas && !recipient.id_level) {
                tag = { title: 'General', color: 'green' }
                type = 'general'
              } else {
                if (recipient.id_kelas) {
                  const kelas = `${recipient.kela.level.nama}-${recipient.kela.simbol}`
                  tag = { title: kelas, color: 'orange' }
                  type = 'id_kelas'
                } else if (recipient.id_level) {
                  const level = recipient.level.nama
                  tag = { title: level, color: 'blue' }
                  type = 'id_level'
                }
              }
              tags.push(tag)
            }
          } else tags.push({ title: 'General', color: 'green' })
          return {
            ...dat,
            tag: tags,
            type,
            lastModified: moment(dat.updatedAt, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY, HH:mm'),
            firstCreated: moment(dat.createdAt, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY, HH:mm'),
            isEditable: false,
          }
        })
      } catch (err) {
        console.log('err fetchAnnouncement :>> ', err)
      }
    },
  },
  created() {
    this.schoolReligion = config.school.religion
    // console.log(config.school)
    this.isMobile()
    this.$store.dispatch('slot/FETCH_TODAY_CLASS', { idGuru: this.user.id })
    this.$store.dispatch('slot/FETCH_ASSIGNMENT_IN_PROGRESS', { idGuru: this.user.id })
    this.$store.dispatch('slot/FETCH_ALL_ASSIGNMENT_IN_PROGRESS', { idGuru: this.user.id })
    this.$store.dispatch('sesi/FETCH_DASHBOARD_PLANNING', { idGuru: this.user.id })
    if (this.institusi.is_announcement) this.fetchAnnouncement()
  },
  computed: {
    totalNilaiPercentage() {
      if (this.allAssignmentInProgress && this.allAssignmentInProgress.data1) {
        return (this.allAssignmentInProgress.data1[0].tugas_graded_all / this.allAssignmentInProgress.data1[0].tugas_submitted_all * 100).toFixed(1)
      }

      return 0
    },
    sesis() {
      return this.$store.state.slot.sesis
    },
    assignmentInProgress() {
      return this.$store.state.slot.assignmentInProgress
    },
    allAssignmentInProgress() {
      return this.$store.state.slot.allAssignmentInProgress
    },
    user() {
      return this.$store.state.user.user
    },
    panggilan() {
      return this.$store.state.user.panggilan
    },
    isLoading() {
      return this.$store.state.isLoading
    },
    biweeklySessionPlanning() {
      return this.$store.state.sesi.biweeklySessionPlanning
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isMobile2() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile2 && !this.isTablet
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";

.card-content:hover {
  text-decoration: underline;
  cursor: pointer;
  color: teal;
}
</style>
